import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import VideoAPI from "api/video/video.api";
import { RootEpic } from "common/define-type";
import { VideoObject } from "common/define-video";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";

interface VideoState {
    message: string,
    isLoading: boolean,
    lstVideos: VideoObject[],
    selectedVideo: VideoObject | undefined
}
const initialStateBootstrap: VideoState = {
    message: '',
    isLoading: false,
    lstVideos: [],
    selectedVideo: undefined
};

const videoSlice = createSlice({
    name: 'Video',
    initialState: initialStateBootstrap,
    reducers: {
        fetchListVideosRequest: (state, action: PayloadAction<void>) => {
            state.isLoading = true;
        },
        fetchListVideosSuccess: (state, action: PayloadAction<VideoObject[]>) => {
            state.isLoading = false;
            state.lstVideos = action.payload
        },

        uploadVideoRequest: (state, action: PayloadAction<FormData>) => {
            state.isLoading = true;
        },
        uploadVideoSuccess: (state, action: PayloadAction<VideoObject>) => {
            state.isLoading = false;
        },

        messageError: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
        },
        updateSelectedVideo: (state, action: PayloadAction<VideoObject | undefined>) => {
            state.selectedVideo = action.payload;
        },
    }
})

const fetchListCamera$: RootEpic = (action$) => action$.pipe(
    filter(fetchListVideosRequest.match),
    switchMap((action) => {
        return VideoAPI.getVideos().pipe(
            map((res) => {
                console.log('------------------');
                console.log(res);
                return videoSlice.actions.fetchListVideosSuccess(res)
            }), catchError((err) => {
                return [videoSlice.actions.messageError(err.message)]
            })
        )
    })
)

const uploadVideo$: RootEpic = (action$) => action$.pipe(
    filter(uploadVideoRequest.match),
    switchMap(action => {
        return VideoAPI.uploadVideos(action.payload).pipe(
            mergeMap((res) => {
                console.log('------------------');
                console.log(res);
                return [videoSlice.actions.uploadVideoSuccess(res), videoSlice.actions.fetchListVideosRequest()]
            }), catchError((err) => {
                return [videoSlice.actions.messageError(err.message)]
            })
        )
    })
)

export const VideoEpics = [
    fetchListCamera$,
    uploadVideo$
]

export const { 
    fetchListVideosRequest,
    updateSelectedVideo,
    uploadVideoRequest
} = videoSlice.actions;
export const videoReducer = videoSlice.reducer;