import SYSTEM_CONSTANTS from "common/constants";

export default class StreamAPI {
    static host: string;
    static getStream = (cameraId: string): string => {
        return `${this.host}/${SYSTEM_CONSTANTS.CAMERA_STREAM.STREAMING_CAMERA(cameraId)}`
    }
    static getRTCStream = (): string => {
        return `${this.host}/${SYSTEM_CONSTANTS.CAMERA_STREAM.RTC_CAMERA}`
    }
}