/* eslint-disable */
import SYSTEM_CONSTANTS from 'common/constants';
import {  Login } from 'common/define-type';
import { NewResponseLogin, ResponseDeparment, ResponseLogin } from 'common/define-identity';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from "rxjs/operators";
import HttpClient from "../http-client";
import JSEncrypt from 'jsencrypt';
import { error } from 'console';
export default class IdentityApi {
    static host = '';
    static hostLicense = '';
    static hostEVN = 'http://10.1.117.7:8181'
    static encryptData(text: string, key: string) {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(key)
        const encypt = jsEncrypt.encrypt(text);
        return encypt || '';
    }
    static getToken(): Observable<string | null> {
        return HttpClient.get(`${IdentityApi.host}/${SYSTEM_CONSTANTS.IDENTITY.CONNECT_TOKEN}`).pipe(
            map((res) => res as string || null)
        );
    }
    static login(body: URLSearchParams): Observable<NewResponseLogin | null>{
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.IDENTITY.LOGIN}`;
        return HttpClient.post(api, body.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).pipe(
            map((res) => res as NewResponseLogin || null, catchError((error) => new Observable)));
        
    }
    
    static forgotPassword(email: string): Observable<any | null>{
        const api = `${IdentityApi.hostLicense}/${SYSTEM_CONSTANTS.LICENSE.FORGOT}/${email}/notify/passwordreset`;
        return HttpClient.post(api, {}).pipe(
            map((res) => res as any || null, catchError((error) => new Observable)));
        
    }

    static loginEVN(body: any): Observable<any> {
        const api = `${IdentityApi.hostEVN}/${SYSTEM_CONSTANTS.IDENTITY.LOGIN_EVN}`;
        console.log(api);
        console.log(body);
        // return HttpClient.post(api, body.toString(), {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     }).pipe(
        //     map(
        //         res => (res as ResponseToken) || null,
        //         catchError((e: AjaxError) => throwError(e)),
        //     ),
        // );
        // return HttpClient.post(api, body).pipe(map(res => res as EVN_ResponseToken))
        return HttpClient.post(api, body, {headers: {
            'Content-Type': 'application/json'
        }}).pipe(map(res => res as any))

        // return HttpClient.post(api, body.toString(), {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     }).pipe(
        //     map(
        //         res => (res as ResponseToken) || null,
        //         catchError((e: AjaxError) => throwError(e)),
        //     ),
        // );
    }
}