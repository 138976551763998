
const SYSTEM_CONSTANTS = {
    IDENTITY: {
        CONNECT_TOKEN: 'clients/publicKey',
        LOGIN: "connect/token",
        LOGIN_EVN: "api/auth/login"
    },
    LICENSE: {
        FORGOT: "users"
    },
    CAMERA_MANAGER: {
        GET_LIST_CAMERA : "cameraManager/cameras",
        CAMERA : "cameraManager/camera",
        REPORT: "reports"        
    },
    VIDEO: {
        GET_ALL_VIDEOS: 'api/video/all',
        UPLOAD_VIDEO: 'api/video/upload'
    },
    PERSON: {
        GET_ALL_PERSON: 'api/Person/all',
        CREATE_PERSON: 'api/Person/create',
        GET_FACE_IMAGES: 'api/Person/{id}/faceImages',
        DELETE_PERSON: 'api/Person/{id}',
        DELETE_IMAGE_PERSON: 'api/Person/{id}/faceImage'
    },
    REPORT: {
        GET_TIME_KEEPING: 'api/reports/timeKeeping',
        DELETE_TIME_KEEPING: 'api/reports/timeKeeping'
    },
    WARNING_AREA: {
        GET_LIST_WARNING_AREAS: "warningAreas",
        CREATE_WARNING_AREAS: "warningAreas/create",
        UPDATE_WARNING_AREAS: 'warningAreas',
        DELETE_WARNING_AREAS: 'warningAreas'
    },
    CAMERA_STREAM: {
        STREAMING_CAMERA: (cameraId: string): string => `${cameraId}/streaming`,
        RTC_CAMERA: "rtcStreaming"
    },
    CONFIG_CONNECT_SOCKET: {
        CONNECTION: "",
        RECEIVE_MESSAGE: "ReceiveMessage",
        USER_IN_ROOM: "UsersInRoom",
        JOIN_ROOM: "JoinRoom"
    }
}

export default SYSTEM_CONSTANTS