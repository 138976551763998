import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { BoostrapEpics, CameraEpics, cameraReducer, LoginEpics, loginReducer, navbarReducer, bootstrapReducer, polygonReducer, videoReducer, VideoEpics, reportReducer, ReportEpics } from './controller';
import { PersonEpics, personReducer } from "./controller/person.slice";

// import { hotelReducer, HotelEpics } from "./controller/hotel.slice";
const rootReducer = combineReducers({
    login: loginReducer,
    navbar: navbarReducer,
    camera: cameraReducer,
    boostrap: bootstrapReducer,
    video: videoReducer,
    polygon: polygonReducer,
    person: personReducer,
    report: reportReducer
});

export const rootEpic = combineEpics(
    ...LoginEpics,
    ...CameraEpics,
    ...BoostrapEpics,
    ...VideoEpics,
    ...PersonEpics,
    ...ReportEpics
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;