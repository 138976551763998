module.exports = {
    palette: {
        primary: '#3EA6FF',
        black: '#00293B',
        black1: '#14284A',
        gray: '#666666',
        'bg-gray': '#FBFBFD',
        sizeIconDefault: "1.5rem",
        sizeIconMd: "1.125rem",
        whiteGray: "#F6F2F7"
    }
}
