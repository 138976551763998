import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CameraAPI from "api/camera/camera.api";
import { RootEpic } from "common/define-type";
import { ParamListCamera } from "common/expand-request";
import { IGetCameraManage, IPostCameraManager, IReportCamera } from "common/models/camera-model";
import Utils from "common/utils";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";

interface CameraState {
    message: string
    isLoading: boolean,
    isShowExpandCamera: boolean,
    isShowLog: boolean,
    listCamera: IGetCameraManage[],
    listLog: IReportCamera[],
    dataConnectionCamera: IGetCameraManage | null
}
const initialStateBootstrap: CameraState = {
    message: "",
    isLoading: false,
    isShowExpandCamera: false,
    listCamera: [],
    dataConnectionCamera: null,
    isShowLog: false,
    listLog: []
};

const cameraSlice = createSlice({
    name: 'camera',
    initialState: initialStateBootstrap,
    reducers: {
        setIsExpandLog: (state, action: PayloadAction<boolean>) => {
            state.isShowLog = action.payload;
        },
        setDefaultSate: (state, action: PayloadAction<string>) => {
            state.message = "";
            state.isLoading = false;
            state.isShowExpandCamera = false;
            state.dataConnectionCamera = null
        },
        messageError: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
        },
        setIsExpandCamera: (state, action: PayloadAction<boolean>) => {
            state.isShowExpandCamera = action.payload;
        },
        fetchListCameraRequest: (state, action: PayloadAction<ParamListCamera>) => {
            state.isLoading = true
        },
        fetchListCameraSuccess: (state, action: PayloadAction<IGetCameraManage[]>) => {
            state.listCamera = action.payload.map(x => ({...(state.listCamera?.find(y => y.id === x.id) ?? {}), ...x}));
            state.isLoading = false
        },
        addCameraRequest: (state, action: PayloadAction<IPostCameraManager>) => {
            state.isLoading = true
        },
        addCameraSuccess: (state, action: PayloadAction<IGetCameraManage | null>) => {
            state.isLoading = false
            state.message = ""
            if(action.payload){
                state.listCamera.push(action.payload)
            }
        },
        connectionCamera: (state, action: PayloadAction<IGetCameraManage | null>) => {
            state.dataConnectionCamera = action.payload;
        },
        setListCameraGrid: (state, action: PayloadAction<IGetCameraManage>) => {
            const { id } = action.payload;
            const index = state.listCamera.findIndex(x => x.id === id);
            if (index !== -1) state.listCamera[index] = action.payload;
            
        },
        deleteCameraRequest: (state, action: PayloadAction<IGetCameraManage>) => {
            state.isLoading = true
        },
        deleteCameraSuccess: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.listCamera.splice(state.listCamera.findIndex((arrow) => arrow.id === action.payload), 1);
            
        },
        addMedia: (state, action: PayloadAction<IGetCameraManage>) => {
            if (state.listCamera?.findIndex === undefined) state.listCamera = [];
            if (action.payload && action.payload.id) {
                state.listCamera = state.listCamera.filter(x => x != null);
                const item = state.listCamera.findIndex(x => x.id === action.payload.id);
                if (item === -1) {
                    state.listCamera[state.listCamera.length + 1] = action.payload;
                } else {
                    state.listCamera[item] = action.payload;
                }
            }
        },
        getLogCamera: (state, action: PayloadAction<{data: IGetCameraManage, query?: Date[]}>) => {
            state.isLoading = true
        },
        setLogCamera: (state, action: PayloadAction<IReportCamera[]>) => {
            state.isLoading = false
            state.listLog = action.payload;
        },
    }
})

const fetchListCamera$: RootEpic = (action$) => action$.pipe(
    filter(fetchListCameraRequest.match),
    switchMap((action) => {
        const queryString = Utils.querySearchToString(action.payload)
        return CameraAPI.getListCamera(queryString).pipe(
            map((res) => {
                return cameraSlice.actions.fetchListCameraSuccess(res)
            }), catchError((err) => {
                return [cameraSlice.actions.messageError(err.message)]
            })
        )
    })
)

const addCamera$: RootEpic = (action$) => action$.pipe(
    filter(addCameraRequest.match),
    switchMap((action) => {
        return CameraAPI.addNewCamera(action.payload).pipe(
            map((res) => {
                return cameraSlice.actions.addCameraSuccess(res)
            }), catchError((err) => {
                return [cameraSlice.actions.messageError(err.message)]
            })
        )
    })
)
const deleteCamera$: RootEpic = (action$) => action$.pipe(
    filter(deleteCameraRequest.match),
    switchMap((action) => {
        const dataCamera  = action.payload
        return CameraAPI.deleteCamera(dataCamera.id).pipe(
            mergeMap((res) => {
                return [ cameraSlice.actions.deleteCameraSuccess(dataCamera.id) ]
            }), catchError((err) => {
                return [cameraSlice.actions.messageError(err.message)]
            })
        )
    })
)

const getLogCamera$: RootEpic = (action$) => action$.pipe(
    filter(getLogCamera.match),
    switchMap((action) => {
        const camera  = action.payload.data;
        return CameraAPI.getLogCamera(camera.id, action.payload.query).pipe(
            mergeMap((res) => {
                return [ cameraSlice.actions.setLogCamera(res) ]
            }), catchError((err) => {
                return [cameraSlice.actions.messageError(err.message)]
            })
        )
    })
)

export const CameraEpics = [
    fetchListCamera$,
    addCamera$,
    deleteCamera$,
    getLogCamera$
]

export const { 
    setIsExpandCamera, 
    setIsExpandLog, 
    fetchListCameraRequest, 
    fetchListCameraSuccess, 
    addCameraRequest, 
    connectionCamera,
    setListCameraGrid,
    deleteCameraRequest,
    setDefaultSate,
    addMedia,
    getLogCamera,
    setLogCamera
} = cameraSlice.actions;
export const cameraReducer = cameraSlice.reducer;