import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReportAPI from "api/report/report.api";
import { IGetTimeKeepingBodyReq, IReportObject } from "common/define-report";
import { RootEpic } from "common/define-type";
import { IGetCameraManage } from "common/models/camera-model";
import { catchError, filter, map, switchMap } from "rxjs/operators";

interface ReportState {
    message: string,
    isLoading: boolean,
    lstReports: IReportObject[]
}
const initialStateBootstrap: ReportState = {
    message: '',
    isLoading: false,
    lstReports: []
};

const reportSlice = createSlice({
    name: 'Report',
    initialState: initialStateBootstrap,
    reducers: {
        fetchListReportsRequest: (state, action: PayloadAction<{data: IGetCameraManage, query?: Date[]}>) => {
            state.isLoading = true;
        },
        fetchListReportsSuccess: (state, action: PayloadAction<IReportObject[]>) => {
            state.lstReports = action.payload
            state.isLoading = false;
        },
        messageError: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
        },
    }
})

const fetchListReport$: RootEpic = (action$) => action$.pipe(
    filter(fetchListReportsRequest.match),
    switchMap((action) => {
        const camera  = action.payload.data;
        const bodyReq: IGetTimeKeepingBodyReq = {
            cameraIds: [camera.id],
            queryFrom: action.payload.query ? action.payload.query[0] : null,
            queryTo: action.payload.query ? action.payload.query[1] : null
        }
        return ReportAPI.getReports(bodyReq).pipe(
            map((res) => {
                console.log(res);
                return reportSlice.actions.fetchListReportsSuccess(res)
            }), catchError((err) => {
                console.log(err);
                return [reportSlice.actions.messageError(err.message)]
            })
        )
    })
)

export const ReportEpics = [
    fetchListReport$
]

export const { 
    fetchListReportsRequest,
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;