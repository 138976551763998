import React from "react";
import { Route } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsPrivateRouter = Props & { children: JSX.Element, path: string, role?: string[] };

function CPrivateRoute({ children, path, role, ...rest }: PropsPrivateRouter): JSX.Element {
    
    return (
        <Route
            {...rest}
            path={path}
            render={(props) => {
                return children
            }}
        />
    );
}

export default CPrivateRoute;