import SYSTEM_CONSTANTS from 'common/constants';
import { VideoObject } from 'common/define-video';
import { Observable } from 'rxjs/internal/Observable';
import { map } from "rxjs/operators";
import HttpClient from "../http-client";
export default class VideoAPI {
    static host = '';

    static getVideos(lstStatuses?: number[]): Observable<VideoObject[] | []> {
        return HttpClient.get(`${VideoAPI.host}/${SYSTEM_CONSTANTS.VIDEO.GET_ALL_VIDEOS}`).pipe(
            map((res) => res as VideoObject[] || [])
        );
    }

    static uploadVideos(formData: FormData): Observable<VideoObject> {
        return HttpClient.post(`${VideoAPI.host}/${SYSTEM_CONSTANTS.VIDEO.UPLOAD_VIDEO}`, formData).pipe(
            map((res) => res as VideoObject)
        );
    }
}