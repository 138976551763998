import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootEpic, SystemConfig } from "common/define-type";
import { filter, map, switchMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import IdentityApi from "api/identity/identity.api"
import CameraAPI from "api/camera/camera.api";
import StreamAPI from "api/camera/stream.api";
import SignalrAPI from "api/camera/signalr.api";
import VideoAPI from "api/video/video.api";
import PersonAPI from "api/person/person.api";
import ReportAPI from "api/report/report.api";


interface BootstrapState {
    systemConfig: SystemConfig;
    isSuccess: boolean
}
const PATH_SYSTEM_CONFIG = `${process.env.PUBLIC_URL}/assets/system-config.json`;
const IS_CONFIG_LOCAL = false;
const DEFAULT_CONFIG: SystemConfig = {
    hostIdentity: 'https://sit.license.hicas.vn/identity',
    hostLicense: 'https://sit.license.hicas.vn/license_manager',
    hostCamera: 'http://localhost:5000/api',
    hostStream: 'https://ai.hicas.vn/camera',
    hostSignal: 'https://ai.hicas.vn/signalr',
    hostVideo: 'https://ai.hicas.vn',
    hostPerson: 'http://localhost:5000',
    hostReport: 'https://ai.hicas.vn'
};
const initialStateBootstrap: BootstrapState = {
    systemConfig: DEFAULT_CONFIG,
    isSuccess: false,
};

function updateHostService(host: SystemConfig) {
    IdentityApi.host = host.hostIdentity;
    IdentityApi.hostLicense = host.hostLicense;
    CameraAPI.host = host.hostCamera;
    StreamAPI.host = host.hostStream;
    SignalrAPI.host = host.hostSignal;
    VideoAPI.host = host.hostVideo;
    PersonAPI.host = host.hostPerson;
    ReportAPI.host = host.hostReport;
}
const bootstrapSlice = createSlice({
    name: 'bootstrap',
    initialState: initialStateBootstrap,
    reducers: {
        setSysytemConfig: (state, action: PayloadAction<SystemConfig>) => {
            updateHostService(action.payload);
            state.systemConfig = action.payload;
            state.isSuccess = true;
        },
        fetchConfig: (state) => {
            state.isSuccess = false;
        }
    }
})

const bootstrap$: RootEpic = (action$) => action$.pipe(
    filter(fetchConfig.match),
    switchMap(() => {
        return ajax.getJSON(PATH_SYSTEM_CONFIG, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).pipe(map(res => {
            const config = IS_CONFIG_LOCAL ? DEFAULT_CONFIG : res as SystemConfig;
            return bootstrapSlice.actions.setSysytemConfig(config)
        }))
    })
);


export const BoostrapEpics = [
    bootstrap$
];

export const { fetchConfig } = bootstrapSlice.actions;
export const bootstrapReducer = bootstrapSlice.reducer;