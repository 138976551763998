import React, { Suspense } from "react";
import { RouterItem } from "common/define-type";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CPrivateRoute from "./CPrivateRouter";
import CLoading from "./CLoading";
// import CPrivateRoute from "./CPrivateRouter";

// const LoginModule = React.lazy(() => import("pages/login/Login"));
const Main = React.lazy(() => import("pages/main/Main"));
const PageNotFound = React.lazy(() => import("pages/404"));
const FaceTesting = React.lazy(() => import("pages/face-testing/main"))
const Person = React.lazy(() => import("pages/person/main"))

const RouterArr: RouterItem[] = [
    {
        path: "/",
        component: Main,
    },
    {
        path: "/face-testing",
        component: FaceTesting
    },
    {
        path: '/person',
        component: Person
    },
    {
        path: "*",
        component: PageNotFound,
    },
];

export default function CMainRouter(): JSX.Element {
    return (
        <Router>
            <Suspense fallback={<CLoading visible={true} fullScreen={true} />}>
                <Switch>
                    <CPrivateRoute path="/">
                        <>
                            <Switch>
                                {RouterArr.map(
                                    ({
                                        path,
                                        component: Component,
                                        noExact,
                                        ...rest
                                    }) => {
                                        return (
                                            <Route
                                                path={path}
                                                component={Component}
                                                key={path}
                                                exact={noExact ? false : true}
                                                {...rest}
                                            />
                                        );
                                    }
                                )}
                            </Switch>
                        </>
                    </CPrivateRoute>
                    {/* {RouterArr.map(({ path, component: Component, noExact, ...rest }) => {
                        return <Route path={path} component={Component} key={path} exact={noExact ? false : true} {...rest} />
                    })} */}
                </Switch>
            </Suspense>
        </Router>
    );
}
