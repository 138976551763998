/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ImageSize {
    width: number,
    height: number
}

interface PolygonState {
    dragPolygonEvent: any,
    isEditPolygons: boolean,
    selectedIdPolygon: string | null,
    deletePolygon: boolean,
    videoAxis: ImageSize | null
}
const initialStateBootstrap: PolygonState = {
    dragPolygonEvent: null,
    isEditPolygons: false,
    selectedIdPolygon: null,
    deletePolygon: false,
    videoAxis: null
};

const polygonSlice = createSlice({
    name: 'navbar',
    initialState: initialStateBootstrap,
    reducers: {
        setDragPolygonEvent: (state, action: PayloadAction<any>) => {
            state.dragPolygonEvent = action.payload;
        },
        setIsEditPolygons: (state, action: PayloadAction<boolean>) => {
            state.isEditPolygons = action.payload;
        },
        setSelectedIdPolygon: (state, action: PayloadAction<string | null>) => {
            state.selectedIdPolygon = action.payload;
        },
        setDeletePolygon: (state, action: PayloadAction<boolean>) => {
            state.deletePolygon = action.payload;
        },
        setVideoAxis: (state, action: PayloadAction<ImageSize | null>) => {
            state.videoAxis = action.payload;
        }
    }
})

export const { setDragPolygonEvent, setIsEditPolygons, setSelectedIdPolygon, setDeletePolygon, setVideoAxis } = polygonSlice.actions;
export const polygonReducer = polygonSlice.reducer;