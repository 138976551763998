import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavbarState {
    isShowRightPanel: boolean
}
const initialStateBootstrap: NavbarState = {
    isShowRightPanel: false
};

const navbarSlice = createSlice({
    name: 'navbar',
    initialState: initialStateBootstrap,
    reducers: {
        setIsShowRightPanel: (state, action: PayloadAction<boolean>) => {
            state.isShowRightPanel = action.payload;
        }
    }
})

export const { setIsShowRightPanel } = navbarSlice.actions;
export const navbarReducer = navbarSlice.reducer;