import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PersonAPI from "api/person/person.api";
import { ICreatePersonBodyReq, IDeleteFaceImagePersonReq, PersonObject } from "common/define-person";
import { RootEpic } from "common/define-type";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";

interface PersonState {
    message: string,
    isLoading: boolean,
    lstPersons: PersonObject[],
    selectedPerson: PersonObject | undefined,
    lstFaceImagesPerson: string[]
}
const initialStateBootstrap: PersonState = {
    message: '',
    isLoading: false,
    lstPersons: [],
    selectedPerson: undefined,
    lstFaceImagesPerson: []
};

const personSlice = createSlice({
    name: 'Person',
    initialState: initialStateBootstrap,
    reducers: {
        fetchListPersonsRequest: (state, action: PayloadAction<void>) => {
            state.isLoading = true;
        },
        fetchListPersonsSuccess: (state, action: PayloadAction<PersonObject[]>) => {
            state.isLoading = false;
            state.lstPersons = action.payload
        },

        addNewPersonRequest: (state, action: PayloadAction<ICreatePersonBodyReq>) => {
            state.isLoading = true;
        },
        addNewPersonSuccess: (state, action: PayloadAction<PersonObject>) => {
            state.isLoading = false;
        },
        messageError: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
        },
        updateSelectedPerson: (state, action: PayloadAction<PersonObject | undefined>) => {
            state.selectedPerson = action.payload;
        },

        getPersonFaceImages: (state, action: PayloadAction<string>) => {
            state.isLoading = true;
        },

        getPersonFaceImagesSuccess: (state, action: PayloadAction<string[]>) => {
            state.lstFaceImagesPerson = action.payload;
            state.isLoading = false;
        },

        deletePersonRequest: (state, action: PayloadAction<string>) => {
            state.isLoading = true;
        },

        deletePersonSuccess: (state, action: PayloadAction<boolean>) => {
            state.isLoading = false;
        },

        // deletePersonFailed: (state, action: PayloadAction<boolean>) => {
        //     state.isLoading = false;
        // },

        deleteFaceImagePersonRequest: (state, action: PayloadAction<IDeleteFaceImagePersonReq>) => {
            state.isLoading = false;
        },
    }
})

const fetchListPerson$: RootEpic = (action$) => action$.pipe(
    filter(fetchListPersonsRequest.match),
    switchMap((action) => {
        return PersonAPI.getPersons().pipe(
            map((res) => {
                console.log(res);
                return personSlice.actions.fetchListPersonsSuccess(res)
            }), catchError((err) => {
                console.log(err);
                return [personSlice.actions.messageError(err.message)]
            })
        )
    })
)

const addANewPerson$: RootEpic = (action$) => action$.pipe(
    filter(addNewPersonRequest.match),
    switchMap((action) => {
        return PersonAPI.addNewPerson(action.payload).pipe(
            mergeMap((res) => {
                return [personSlice.actions.addNewPersonSuccess(res), personSlice.actions.fetchListPersonsRequest()]
            }), catchError((err) => {
                console.log(err);
                return [personSlice.actions.messageError(err.message)]
            })
        )
    })
)

const deletePerson$: RootEpic = (action$) => action$.pipe(
    filter(deletePersonRequest.match),
    switchMap((action) => {
        return PersonAPI.deletePerson(action.payload).pipe(
            mergeMap((res) => {
                return [personSlice.actions.deletePersonSuccess(res), personSlice.actions.fetchListPersonsRequest()]
            }), catchError((err) => {
                console.log(err);
                return [personSlice.actions.messageError(err.message)]
            })
        )
    })
)

const deleteFaceImagePersonRequest$: RootEpic = (action$) => action$.pipe(
    filter(deleteFaceImagePersonRequest.match),
    switchMap((action) => {
        return PersonAPI.deleteFaceImagePerson(action.payload.id, action.payload.body).pipe(
            mergeMap((res) => {
                return [personSlice.actions.deletePersonSuccess(res), personSlice.actions.getPersonFaceImages(action.payload.id)]
            }), catchError((err) => {
                console.log(err);
                return [personSlice.actions.messageError(err.message)]
            })
        )
    })
)

const getPersonFaceImages$: RootEpic = (action$) => action$.pipe(
    filter(getPersonFaceImages.match),
    switchMap((action) => {
        return PersonAPI.getPersonFaceImages(action.payload).pipe(
            map((res) => {
                return personSlice.actions.getPersonFaceImagesSuccess(res);
            }), catchError((err) => {
                console.log(err);
                return [personSlice.actions.messageError(err.message)]
            })
        )
    })
)

export const PersonEpics = [
    fetchListPerson$,
    addANewPerson$,
    getPersonFaceImages$,
    deletePerson$,
    deleteFaceImagePersonRequest$
]

export const { 
    fetchListPersonsRequest,
    updateSelectedPerson,
    addNewPersonRequest,
    getPersonFaceImages,
    deletePersonRequest,
    deleteFaceImagePersonRequest
} = personSlice.actions;
export const personReducer = personSlice.reducer;