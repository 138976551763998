import SYSTEM_CONSTANTS from 'common/constants';
import { IGetCameraManage, IPostCameraManager, IPostWarningAreas, IPutWarningAreas, IReportCamera, IWarningArea } from 'common/models/camera-model';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from "rxjs/operators";
import HttpClient from "../http-client";
export default class CameraAPI {
    static host = '';

    static getListCamera(param: string): Observable<IGetCameraManage[] | []> {
        return HttpClient.get(`${CameraAPI.host}/${SYSTEM_CONSTANTS.CAMERA_MANAGER.GET_LIST_CAMERA}`).pipe(
            map((res) => res as IGetCameraManage[] || [])
        );
    }
    static getCameraById(idCamera: string): Observable<IGetCameraManage | null>{
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.CAMERA_MANAGER.GET_LIST_CAMERA}/${idCamera}`;
        return HttpClient.get(api).pipe(
            map((res) => res as IGetCameraManage || null));
    }
    
    static addNewCamera(dataAddCamera: IPostCameraManager): Observable<IGetCameraManage | null>{
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.CAMERA_MANAGER.CAMERA}`;
        return HttpClient.post(api, dataAddCamera).pipe(
            map((res) => res as IGetCameraManage || null, catchError((error) => new Observable)));
        
    }
    static updateCamera(idCamera: string, forceAnalysis: boolean): Observable<boolean | null>{
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.CAMERA_MANAGER.CAMERA}/${idCamera}/start?forceAnalysis=${forceAnalysis}`;
        return HttpClient.put(api, {}).pipe(
            map((res) => res as boolean || null));
        
    }
    static deleteCamera(idCamera: string): Observable<boolean | null>{
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.CAMERA_MANAGER.CAMERA}/${idCamera}`;
        return HttpClient.delete(api, {}).pipe(
            map((res) => res as boolean || null));
        
    }
    static getStreamVideo(src: string): Observable<string>{
        return HttpClient.get(src).pipe(
            map((res) => res as string));
        
    }
    static getLogCamera = (cameraId: string, date?: Date[]): Observable<IReportCamera[]> => {
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.CAMERA_MANAGER.REPORT}?CameraIds=${cameraId}${date ? ("&QueryFrom=" + date[0].toUTCString() + "&QueryTo=" + date[1].toUTCString()) : ""}`; 
        return HttpClient.get(api).pipe(map(res => res as IReportCamera[]))
    }

    static getWarningAreasCamera = (cameraId: string): Observable<IWarningArea[]> => {
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.WARNING_AREA.GET_LIST_WARNING_AREAS}?CameraId=${cameraId}`; 
        return HttpClient.get(api).pipe(map(res => res as IWarningArea[]))
    }

    static createWarningAreasCamera = (body: IPostWarningAreas): Observable<IWarningArea[]> => {
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.WARNING_AREA.CREATE_WARNING_AREAS}`; 
        return HttpClient.post(api, body).pipe(map(res => res as IWarningArea[]))
    }

    static updateWarningAreasCamera = (body: IPutWarningAreas): Observable<IWarningArea[]> => {
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.WARNING_AREA.UPDATE_WARNING_AREAS}`; 
        return HttpClient.put(api, body).pipe(map(res => res as IWarningArea[]))
    }

    static deleteWarningAreasCamera = (body: string[]): Observable<IWarningArea[]> => {
        let suffixParam = '?';
        body.forEach((item, index) => {
            suffixParam += 'Ids='+item;
            if(index < item.length - 1){
                suffixParam += '&';
            }
        })
        const api = `${CameraAPI.host}/${SYSTEM_CONSTANTS.WARNING_AREA.DELETE_WARNING_AREAS}${suffixParam}`; 
        return HttpClient.delete(api).pipe(map(res => res as IWarningArea[]))
    }
}