/* eslint-disable @typescript-eslint/no-explicit-any */
import SYSTEM_CONSTANTS from 'common/constants';
import { ICreatePersonBodyReq, IFaceImageNamePersonReq, PersonObject } from 'common/define-person';
import { Observable } from 'rxjs/internal/Observable';
import { map } from "rxjs/operators";
import HttpClient from "../http-client";
export default class PersonAPI {
    static host = '';

    static getPersons(): Observable<PersonObject[] | []> {
        return HttpClient.get(`${PersonAPI.host}/${SYSTEM_CONSTANTS.PERSON.GET_ALL_PERSON}`).pipe(
            map((res) => res as PersonObject[] || [])
        );
    }

    static addNewPerson(bodyReq: ICreatePersonBodyReq): Observable<PersonObject> {
        return HttpClient.post(`${PersonAPI.host}/${SYSTEM_CONSTANTS.PERSON.CREATE_PERSON}?Name=${bodyReq.name}`, bodyReq.ListFiles).pipe(
            map((res) => res as PersonObject)
        );
    }

    static getPersonFaceImages(id: string): Observable<string[] | any> {
        return HttpClient.get(`${PersonAPI.host}/${SYSTEM_CONSTANTS.PERSON.GET_FACE_IMAGES.replace('{id}', id)}`).pipe(
            map((res) => res as string[])
        );
    }

    static deletePerson(id: string): Observable<boolean | any> {
        return HttpClient.delete(`${PersonAPI.host}/${SYSTEM_CONSTANTS.PERSON.DELETE_PERSON.replace('{id}', id)}`).pipe(
            map((res) => res as boolean)
        );
    }

    static deleteFaceImagePerson(id: string, body: IFaceImageNamePersonReq): Observable<boolean | any> {
        return HttpClient.delete(`${PersonAPI.host}/${SYSTEM_CONSTANTS.PERSON.DELETE_IMAGE_PERSON.replace('{id}', id)}?fileName=${body.imageName}`).pipe(
            map((res) => res as boolean)
        );
    }
}