/* eslint-disable @typescript-eslint/no-explicit-any */
import SYSTEM_CONSTANTS from 'common/constants';
import { IGetTimeKeepingBodyReq, IReportObject } from 'common/define-report';
import { Observable } from 'rxjs/internal/Observable';
import { map } from "rxjs/operators";
import HttpClient from "../http-client";
export default class ReportAPI {
    static host = '';

    static getReports(bodyReq: IGetTimeKeepingBodyReq): Observable<IReportObject[]> {
        const url = `${ReportAPI.host}/${SYSTEM_CONSTANTS.REPORT.GET_TIME_KEEPING}?CameraIds=${bodyReq.cameraIds[0]}${(bodyReq.queryFrom ? "&QueryFrom=" + bodyReq.queryFrom.toUTCString() : "") + (bodyReq.queryTo ? "&QueryTo=" + bodyReq.queryTo.toUTCString() : "")}`;
        return HttpClient.get(url).pipe(
            map((res) => res as IReportObject[] || [])
        );
    }
}